import { template as template_80f7839ef95e40f598dabb0ee892e77f } from "@ember/template-compiler";
const WelcomeHeader = template_80f7839ef95e40f598dabb0ee892e77f(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
