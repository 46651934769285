import { template as template_3e06d49efdba42b6b83ab1259f292952 } from "@ember/template-compiler";
const FKLabel = template_3e06d49efdba42b6b83ab1259f292952(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
