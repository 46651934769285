import { template as template_94b732130fe04c6bbbeccc7cedf7d54c } from "@ember/template-compiler";
const FKText = template_94b732130fe04c6bbbeccc7cedf7d54c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
