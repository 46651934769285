import { template as template_548c853ee16b4f02965cd0c6a20cb4f9 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_548c853ee16b4f02965cd0c6a20cb4f9(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
